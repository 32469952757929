import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/navigation'
import { Navigation } from 'swiper/modules'
import { GHref } from 'mp-common/components/form/elements'
import dynamic from 'next/dynamic'
import { defaultFreightBoardFilters } from 'mp-freight/hooks/useFreightBoardFilters'

const EllipsisToolTip = dynamic(() => import('ellipsis-tooltip-react-chan'), { ssr: false })

const SliderCarousel = ({ items, onClick, onDelete, queryForm, formatForm }) => (
  <div className="swiper-container">
    {items?.length > 0
      ? (
        <Swiper
          navigation
          modules={[Navigation]}
          className="mySwiper"
          slidesPerView="auto"
          spaceBetween={8}
        >
          {items?.map((item) => (
            <SwiperSlide key={item?.name} className={formatForm({ ...defaultFreightBoardFilters, ...item?.form }) === formatForm(queryForm) ? 'active' : ''}>
              <div className="overflow-hidden flex-1 d-flex" onClick={() => onClick(item?.form)} key={item?.name}>
                <div className="d-flex">
                  <EllipsisToolTip>{item?.name}</EllipsisToolTip>
                </div>
              </div>
              {onDelete && !item?.isDefault && (
                <GHref className="pl-8 pr-4" onClick={() => onDelete(item)}>
                  <i className="fa fa-times"></i>
                </GHref>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )
      : null}
  </div>

)
export default SliderCarousel
