import { useCallback, useEffect, useMemo, useState } from 'react'
import { apiGet, apiPost } from 'mp-structure/libs/api'
import get from 'lodash/get'
import useSession from 'mp-auth/hooks/useSession'
import { removeApiV2 } from 'mp-common/hooks/useViewOptions'

export type QuickFilter = {
  name: string,
  form: any
}

const useQuickFilters = ({ applyFilter }) => {
  const [quickFilters, setQuickFilters] = useState<QuickFilter[]>([])
  const { user } = useSession()

  const updateQuickFilters = async (quickFilter, isRemove = false) => {
    let _filters = quickFilters

    if (isRemove) {
      const filterIndex = quickFilters.findIndex((f) => f?.name === quickFilter?.name)
      if (filterIndex > -1) {
        _filters.splice(filterIndex, 1)
      }
    } else {
      _filters = [..._filters, quickFilter]
    }

    const { data } = await apiPost('uistorage/update', {
      type: 'freightBoard_quickFilters',
      data: JSON.stringify(_filters.filter((f) => !!f))
    })

    if (data.status === 'ok') {
      setQuickFilters([..._filters])
      if (!isRemove) {
        applyFilter(quickFilter.form)
      }
    }
  }

  const savedFiltersUrl = useMemo(() => (
    get(user, 'data.UIStorage.freightBoard_quickFilters')
  ), [user])

  const getSavedFilters = useCallback(async () => {
    const jsonUrl = savedFiltersUrl
    if (jsonUrl) {
      const response = await apiGet(removeApiV2(jsonUrl))
      if (response) {
        setQuickFilters(response)
      }
    }
  }, [savedFiltersUrl])

  useEffect(() => {
    getSavedFilters()
  }, [savedFiltersUrl])

  return {
    quickFilters,
    setQuickFilters,
    updateQuickFilters
  }
}

export default useQuickFilters
