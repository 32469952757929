import { useState } from 'react'
import { freightApiPut } from 'mp-structure/libs/freightApi'

const useDeleteFreight = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const deleteFreight = async (freightId: string) => {
    setLoading(true)
    try {
      await freightApiPut(`shipment/${freightId}/un-publish`)
      return true
    } catch (e) {
      setError(e.message)
      return false
    } finally {
      setLoading(false)
    }
  }

  return { deleteFreight, loading, error }
}

export default useDeleteFreight
