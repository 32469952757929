import { useCallback, useEffect, useMemo } from 'react'
import Freight from 'mp-freight/components/Freight'
import QuickView from 'mp-freight/components/quickview/QuickView'
import useTranslation from 'next-translate/useTranslation'
import useSession from 'mp-auth/hooks/useSession'
import useFreightBoard from 'mp-freight/hooks/useFreightBoard'
import isEmpty from 'lodash/isEmpty'
import { useToast } from 'mp-common/hooks/useToast'
import useDeleteFreight from 'mp-freight/hooks/useDeleteFreight'
import { useRouter } from 'next/router'
import InfiniteScroll from 'mp-common/components/InfiniteScroll'
import useFreightBoardFilters, { defaultFreightBoardFilters } from 'mp-freight/hooks/useFreightBoardFilters'
import { formToQuery } from 'mp-fex/components/QuickFilters'

interface Props {
  setSummary: (item:any)=>void,
  fetchSummary: () => void,
  headers: any,
  showScroll: any
}

function FreightList(props: Props): JSX.Element {
  const { setSummary, fetchSummary, headers, showScroll } = props
  const { t } = useTranslation()
  const { isShipper, isCarrier } = useSession()
  const { deleteFreight, loading: loadingDeleteFreight } = useDeleteFreight()
  const { toastSuccess, toastError } = useToast()
  const { query } = useRouter()
  const { form: localForm, pagination, setPagination } = useFreightBoardFilters()

  const {
    fetchFreightList,
    freightList,
    showRowMenu,
    setShowRowMenu,
    isRemoveConfirmed,
    setIsRemoveConfirmed,
    listLoading,
    selectAndShowQuickView,
    setShowQuickView,
    showQuickView,
    selectedListItem,
    resetQuickView
  } = useFreightBoard({ setSummary, fetchSummary, localForm, pagination, setPagination })

  const removeFreight = useCallback(async (id, loading_location, unloading_location) => {
    if (!isRemoveConfirmed) {
      return setIsRemoveConfirmed(true)
    }
    const response = await deleteFreight(id)
    if (response) {
      toastSuccess(t('freight:removed_success', {
        loading_location,
        unloading_location
      }))
      setShowRowMenu('')
      resetQuickView()
      setIsRemoveConfirmed(false)

      if (!isEmpty(localForm) && JSON.stringify(localForm) !== JSON.stringify(defaultFreightBoardFilters)) {
        await fetchFreightList(localForm)
      } else {
        await fetchFreightList()
      }
    } else {
      toastError('errors:somethingWentWrong')
    }
  }, [isRemoveConfirmed, query])

  const renderFreightList = useMemo(() => {
    if (!freightList || freightList?.length === 0) {
      return (
        <div className="tm-trans-rows-all tm-custom-scroll w-100 d-flex">
          {isEmpty(query)
            ? (isCarrier
              ? <span className="m-24">{t('freight:no_freights_carrier')}</span>
              : <span className="m-24">{t('freight:no_freights_shipper')}</span>)
            : <span className="m-24">{t('freight:no_freight_found')}</span>}
        </div>
      )
    }

    return (
      <div className="tm-trans-rows-all" id="infinite-scroll-table">
        <InfiniteScroll
          fetchItems={() => fetchFreightList({ ...localForm, page: pagination.page + 1 }, true)}
          hasMore={pagination.total > freightList.length}
          loading={listLoading}
        >
          <table className="tm-table-transactions tm-table-transactions-content">
            <tbody>
              {freightList?.map((freight) => (
                <Freight
                  freight={freight}
                  showScroll={showScroll(headers)}
                  key={`freight_${freight?.id}`}
                  headers={headers}
                  selectAndShowQuickView={selectAndShowQuickView}
                  removeFreight={removeFreight}
                  showRowMenu={showRowMenu}
                  setShowRowMenu={setShowRowMenu}
                  isRemoveConfirmed={isRemoveConfirmed}
                  loadingDeleteFreight={loadingDeleteFreight}
                />
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    )
  }, [freightList, pagination.page, fetchFreightList, headers, isCarrier, isShipper, showQuickView, localForm, query, showRowMenu, isRemoveConfirmed, loadingDeleteFreight])

  useEffect(() => (setIsRemoveConfirmed(false)), [showQuickView, setShowQuickView])

  return (
    <div className="tm-content-w-left-bottom">
      {renderFreightList}
      <QuickView
        showQuickView={showQuickView}
        selectedListItem={selectedListItem}
        resetQuickView={resetQuickView}
        isRemoveConfirmed={isRemoveConfirmed}
        remove={removeFreight}
      />
    </div>
  )
}

export default FreightList
