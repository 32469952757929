
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import GeneralLayout from 'mp-structure/layouts/views/GeneralLayout';
import GeneralLayoutHeaderTop from 'mp-structure/layouts/components/header/GeneralLayoutHeaderTop';
import GeneralLayoutSidebar from 'mp-structure/layouts/views/GeneralLayoutSidebar';
import GeneralLayoutMain from 'mp-structure/layouts/views/GeneralLayoutMain';
import MarketplaceWrapper from 'mp-fex/components/MarketplaceWrapper';
import FreightBoardView from 'mp-freight/views/FreightBoardView';
import useTranslation from 'next-translate/useTranslation';
import GeneralLayoutMeta from 'mp-structure/layouts/views/GeneralLayoutMeta';
import GeneralLayoutHeader from 'mp-structure/layouts/components/header/GeneralLayoutHeader';
function FreightBoard() {
    const { t } = useTranslation();
    return (<GeneralLayout>
      <GeneralLayoutHeader />
      <GeneralLayoutMeta meta={{ title: t('sidebar:freight_board') }}/>
      <GeneralLayoutSidebar />
      <GeneralLayoutHeaderTop />
      <GeneralLayoutMain>
        <MarketplaceWrapper className="tm-content-w-left" page="freight">
          <FreightBoardView />
        </MarketplaceWrapper>
      </GeneralLayoutMain>
    </GeneralLayout>);
}
export default FreightBoard;

    async function __Next_Translate__getStaticProps__191e5d01680__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/fex/freight-board/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191e5d01680__ as getStaticProps }
  