import { useEffect, useMemo, useState } from 'react'
import { handleScroll, parseViewOptions } from 'mp-structure/libs/utils'
import ViewOptions from 'mp-freight/components/ViewOptions'
import useBoardTable from 'mp-common/hooks/useBoardTable'
import useTranslation from 'next-translate/useTranslation'
import TableSummary from 'mp-fex/components/table/TableSummary'
import TableHeader, { HeaderType } from 'mp-common/components/table/TableHeader'
import ScrollableTableHeader from 'mp-common/components/table/ScrollableTableHeader'
import { BoardNames } from 'mp-common/types/BoardNames'
import useFreightSummary from 'mp-freight/hooks/useFreightSummary'
import GeneralLayoutScrollToTop from 'mp-structure/layouts/components/GeneralLayoutScrollToTop'
import useFreightBoardFilters, { defaultFreightBoardFilters } from 'mp-freight/hooks/useFreightBoardFilters'
import FreightList from 'mp-freight/components/FreightList'
import QuickFilters from 'mp-fex/components/QuickFilters'
import useUserProfile from 'mp-profile/hooks/useUserProfile'
import useQuickFilters from 'mp-fex/hooks/useQuickFilters'
import Filters from 'mp-freight/components/Filters'
import { isEmpty } from 'lodash'
import useSession from 'mp-auth/hooks/useSession'
import GHref from '../../common/components/form/elements/GHref'
import GImage from '../../common/components/form/elements/GImage'

function FreightBoardView(): JSX.Element {
  const [showFilters, setShowFilters] = useState(false)
  const [showViewOptions, setShowViewsOptions] = useState(false)
  const [scrollableHeaders, setScrollableHeaders] = useState<HeaderType[]>([])
  const { t } = useTranslation()
  const { isCarrier, user: info } = useSession()
  const { user } = useUserProfile()
  const { summary, fetchSummary, setSummary } = useFreightSummary(false)
  const { resetFilters, applyFilter, form: queryForm } = useFreightBoardFilters()
  const { freightBoardHeaders: headers, setFreightBoardHeaders: setHeaders, showScroll } = useBoardTable()
  const { quickFilters, updateQuickFilters } = useQuickFilters({ applyFilter })

  const renderTableHeaders = useMemo(() => (
    <div className="tm-table-transactions-out">
      <table className="tm-table-transactions">
        <thead>
          <tr className="headings">
            <th className="col-checkbox" />
            {headers.map((x) => x.category === 'constants' && (x?.columns?.map((y) => (
              <TableHeader header={y} key={y.key} />
            ))))}
            {showScroll(headers) && <ScrollableTableHeader scrollFunction={handleScroll} headerList={scrollableHeaders} />}
            <th className="col-checkbox settings" style={{ textAlign: 'left' }}>
              <GHref
                className="tm-table-trans-set-btn"
                onClick={() => setShowViewsOptions(true)}
              >
                <GImage
                  src="icon-settings.svg"
                  alt="Settings"
                  width={32}
                  height={32}
                />
              </GHref>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  ), [headers, scrollableHeaders])

  useEffect(() => {
    setScrollableHeaders([])
    headers.forEach((h) => {
      if (h.scrollable) {
        setScrollableHeaders(scrollableHeaders => [...scrollableHeaders, h])
      }
    })
  }, [headers])

  useEffect(() => {
    const localOptions = localStorage.getItem('viewOptions_' + BoardNames.FreightList)
    if (localOptions) {
      setHeaders(parseViewOptions(headers, JSON.parse(localOptions)))
    }
  }, [])

  useEffect(() => {
    if (JSON.stringify(queryForm) === JSON.stringify(defaultFreightBoardFilters) || isEmpty(queryForm)) {
      handleScroll('')
    }
  }, [JSON.stringify(queryForm)])

  return (
    <div className="tm-content-w-left-bottom">
      <div className="tm-content-w-right-content">
        <TableSummary
          summary={summary}
          link="/fex/freight-board/add"
          title={t('sidebar:add_new_freight')}
          summaryKey="table:x_freight"
          setShowFilters={setShowFilters}
          resetFilters={resetFilters}
        />
      </div>
      <QuickFilters
        defaultQuickFilters={[
          { name: t('common:all'), form: defaultFreightBoardFilters },
          ...(!isCarrier
            ? [{ name: t('freight:my_freights'), form: { ...defaultFreightBoardFilters, companyName: info?.company?.name, contactName: `${user.firstName} ${user.lastName}` } }]
            : []
          )
        ]}
        quickFilters={quickFilters}
        updateQuickFilters={updateQuickFilters}
        applyFilter={applyFilter}
        queryForm={queryForm}
      />
      {renderTableHeaders}
      <FreightList headers={headers} showScroll={showScroll} fetchSummary={fetchSummary} setSummary={setSummary} />
      <GeneralLayoutScrollToTop />
      <Filters
        setShowFilters={setShowFilters}
        showFilters={showFilters}
        applyFilter={applyFilter}
        queryForm={queryForm}
        withFreightTypeFilter
        withPublicationDateFilter
        withTruckTypeFilter
        withSpecialRequestsFilter
        isFreightBoard
        setSavedFilters={(item) => updateQuickFilters(item, false)}
      />
      <ViewOptions headers={headers} setHeaders={setHeaders} boardName={BoardNames.FreightList} setShowViewsOptions={setShowViewsOptions} showViewOptions={showViewOptions} />
    </div>
  )
}

export default FreightBoardView
