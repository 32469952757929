import { useEffect, useMemo, useState } from 'react'
import ContactInformationSection from 'mp-freight/components/quickview/sections/ContactInformationSection'
import RouteInformationSection from 'mp-freight/components/quickview/sections/RouteInformationSection'
import FreightInformationSection from 'mp-freight/components/quickview/sections/FreightInformationSection'
import TruckInformationSection from 'mp-freight/components/quickview/sections/TruckInformationSection'
import useQuickView from 'mp-freight/hooks/useQuickView'
import useTranslation from 'next-translate/useTranslation'
import useSession from 'mp-auth/hooks/useSession'
import { ReactSVG } from 'react-svg'
import moment from 'moment/moment'
import GHref from '../../../common/components/form/elements/GHref'
import InformationSection from './sections/InformationSection'
import SummaryHeaderSection from './sections/SummaryHeaderSection'

interface Props {
  selectedListItem: any
  showQuickView?: boolean
  detailedTruckInformation?: boolean
  isRemoveConfirmed?: boolean
  remove?: (id: any, loadingLocation: string, unloadingLocation: string) => Promise<void>
  resetQuickView?: () => void
}

function QuickView(props: Props): JSX.Element {
  const {
    showQuickView,
    selectedListItem,
    detailedTruckInformation,
    isRemoveConfirmed,
    remove,
    resetQuickView
  } = props
  const { t } = useTranslation()
  const { isCarrier } = useSession()
  const { activeFreight } = useQuickView({ selectedListItem, showQuickView, isTenderView: false })
  const [isListenerMounted, setIsListenerMounted] = useState(false)

  const isEditable = useMemo(() => !isCarrier && activeFreight?.isEditable, [activeFreight?.isEditable, isCarrier])
  const isRemovable = useMemo(() => !isCarrier && activeFreight?.isEditable, [activeFreight?.isEditable, isCarrier])

  const emailContent = useMemo(() => {
    if (activeFreight) {
      const pickup = activeFreight?.stops[0]
      const delivery = activeFreight?.stops[activeFreight.stops.length - 1]

      return ({
        email: activeFreight?.user?.email,
        subject: `Offer in TenndersFEX - Load from ${pickup?.location?.label} to ${delivery?.location?.label}`,
        body: `Hello ${activeFreight?.user?.name},%0D%0A%0D`
          + `Company: ${activeFreight?.company?.vatId}, ${activeFreight?.company?.name}%0D%0A%0D`
          + `Loading: ${pickup?.location?.label} - ${pickup?.firstDate ? moment(pickup?.firstDate)?.format('DD/MM') : ''}${pickup?.lastDate
            ? ('-' + String(moment(pickup?.lastDate)?.format('DD/MM')))
            : ''}%0D%0A%0D`
          + `Unloading: ${delivery?.location?.label} - ${delivery?.firstDate ? moment(delivery?.firstDate)?.format('DD/MM') : ''}${delivery?.lastDate
            ? ('-' + String(moment(delivery?.lastDate)?.format('DD/MM')))
            : ''}%0D%0A%0D`
          + `Freight Details: ${t(`options:${activeFreight?.goodType?.value}`)}, ${
            activeFreight?.freightProps?.weight}${t('common:weight_suffix')}, ${activeFreight?.freightProps?.length}${t('common:length_suffix')}%0D%0A%0D`
          + `Required Truck: ${activeFreight?.truckType?.value}${t('common:volume_suffix')}`
      })
    }
    return { email: '', body: '', subject: '' }
  }, [activeFreight])

  const escFunction = (e) => {
    if (e.key === 'Escape' && resetQuickView) {
      resetQuickView()
    }
  }

  useEffect(() => {
    if (!isListenerMounted && showQuickView) {
      setIsListenerMounted(true)
      document.removeEventListener('keydown', escFunction)
      document.addEventListener('keydown', escFunction, false)
    }
    return () => {
      document.removeEventListener('keydown', escFunction)
      setIsListenerMounted(false)
    }
  }, [showQuickView])

  return (
    <div>
      {showQuickView && activeFreight?.id && (
        <aside className="tm-right-sidebar tm-transactions-details-sidebar tm-ftt-right-sidebar opened">
          <GHref
            className="tm-close-sidebar"
            onClick={() => resetQuickView()}
          />
          <SummaryHeaderSection activeFreight={activeFreight} />
          <div className="tm-custom-scroll tm-right-sidebar-content">
            <ContactInformationSection contact={activeFreight?.user} emailContent={emailContent} />
            <InformationSection activeFreight={activeFreight} />
            <RouteInformationSection withInstructions routes={activeFreight?.stops} mapsLink={activeFreight?.mapsLink} />
            <FreightInformationSection freight={activeFreight} additionalInfo={activeFreight?.additionalInfo} />
            <TruckInformationSection truck={activeFreight} detailedTruckInformation={detailedTruckInformation} />
          </div>
          <div className="tm-transactions-bottom-buttons">
            {isEditable ? (
              <GHref
                className="tm-button-bordered color-brown d-flex"
                href={`/fex/freight-board/edit/${activeFreight?.id}`}
              >
                <ReactSVG
                  src="/img/icon-edit.svg"
                  className="btn-icon-wrapper mr-4"
                  beforeInjection={(svg) => svg.classList.add('btn-icon-medium', 'stroke-color-black-70')}
                />
                {t('common:edit')}
              </GHref>
            ) : null}
            {isRemovable ? (
              <GHref
                className="confirm d-flex"
                onClick={() => remove(
                  activeFreight.id,
                  activeFreight?.stops[0]?.location?.label,
                  activeFreight?.stops[activeFreight.stops.length - 1]?.location?.label
                )}
              >
                <ReactSVG
                  src="/img/icon-trash.svg"
                  className="btn-icon-wrapper mr-4"
                  beforeInjection={(svg) => svg.classList.add('btn-icon-medium', 'fill-color-yellow-100')}
                />
                {isRemoveConfirmed ? t('common:confirm') : t('common:remove')}
              </GHref>
            ) : null}
          </div>
        </aside>
      )}
    </div>
  )
}

export default QuickView
