import React, { Dispatch, SetStateAction, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { HeaderType } from 'modules/common/components/table/TableHeader'
import { useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { domainURL } from 'mp-structure/libs/api'
import { GHref, GImage } from 'mp-common/components/form/elements'
import useSession from 'mp-auth/hooks/useSession'
import ColumnPickup from 'mp-fex/components/table/columns/ColumnPickup'
import ColumnKmStops from 'mp-fex/components/table/columns/ColumnKmStops'
import ColumnDelivery from 'mp-fex/components/table/columns/ColumnDelivery'
import ColumnCompany from 'mp-fex/components/table/columns/ColumnCompany'
import ColumnPrice from 'mp-fex/components/table/columns/ColumnPrice'
import ColumnFreightType from 'mp-fex/components/table/columns/ColumnFreightType'
import ColumnFreightInformation from 'mp-fex/components/table/columns/ColumnFreightInformation'
import ColumnTruckInformation from 'mp-fex/components/table/columns/ColumnTruckInformation'
import ColumnSpecialRequirements from 'mp-fex/components/table/columns/ColumnSpecialRequirements'
import ColumnPublicationDate from 'mp-fex/components/table/columns/ColumnPublicationDate'
import {
  HEADER_FREIGHT_INFORMATION, HEADER_FREIGHT_TYPE,
  HEADER_PRICE,
  HEADER_PUBLICATION_DATE,
  HEADER_SPECIAL_REQS, HEADER_TRUCK_INFORMATION
} from 'mp-common/hooks/useBoardTable'
import ColumnScrollablePrice from 'mp-fex/components/table/columns/ColumnScrollablePrice'
import { useRouter } from 'next/router'
import { isEmpty } from 'lodash'

interface Props {
  freight?: any
  selectAndShowQuickView?: any
  headers: HeaderType[]
  showScroll: boolean
  removeFreight?: (id: any, loadingLocation: string, unloadingLocation: string) => Promise<void>
  isRemoveConfirmed?: boolean
  showRowMenu?: string
  setShowRowMenu?: Dispatch<SetStateAction<string>>
  loadingDeleteFreight?: boolean
}

function Freight(props: Props): JSX.Element {
  const {
    headers,
    showScroll,
    freight,
    selectAndShowQuickView,
    removeFreight,
    isRemoveConfirmed,
    showRowMenu,
    setShowRowMenu,
    loadingDeleteFreight
  } = props
  const { t } = useTranslation()
  const { query } = useRouter()
  const unreadClass = freight.isNew ? ' new-load' : ''
  const { isCarrier } = useSession()
  const countryFlagEndpoint = useSelector((state: StateType) => state.data.countryFlagEndpoint)
  const flagURL = useMemo(() => (domainURL + countryFlagEndpoint), [domainURL, countryFlagEndpoint])
  const isAnyOperationAllowed = useMemo(() => !isCarrier && freight?.isEditable, [freight?.isEditable, isCarrier])

  const getHeaders = (h) => {
    if (h?.category === HEADER_FREIGHT_TYPE) {
      return <ColumnFreightType header={h} row={freight} name="shipmentType.value" />
    }
    if (h?.category === HEADER_TRUCK_INFORMATION) {
      return <ColumnTruckInformation header={h} row={freight} />
    }
    if (h?.category === HEADER_PUBLICATION_DATE) {
      return <ColumnPublicationDate header={h} row={freight} />
    }
    if (h?.category === HEADER_FREIGHT_INFORMATION) {
      return <ColumnFreightInformation header={h} row={freight} />
    }
    if (h?.category === HEADER_SPECIAL_REQS) {
      return <ColumnSpecialRequirements header={h} row={freight} />
    }

    return null
  }

  return (
    <tr className="content">
      <td className={`col-checkbox${unreadClass}`} />
      <ColumnPickup
        pickup={freight?.firstPickup}
        selectAndShowQuickView={() => selectAndShowQuickView(freight)}
        flagURL={flagURL}
      />
      <ColumnKmStops href={freight.mapsLink} row={freight} />
      <ColumnDelivery
        delivery={freight?.lastDelivery}
        selectAndShowQuickView={() => selectAndShowQuickView(freight)}
        flagURL={flagURL}
      />
      <ColumnCompany company={freight?.company} />
      <ColumnPrice row={freight} />
      {showScroll && (
        <td
          colSpan={3}
          className="tm-extra-info-column inner-content p-0"
        >
          <div className="tm-extra-columns-outer">
            <div className="tm-table-left" />
            <div className="tm-table-extra-columns-outer">
              <table className="tm-table-transactions tm-extra-columns">
                <tbody>
                  <tr>
                    {headers?.map((h) => h?.category !== 'constants' && h?.visible && (
                      <td key={h?.category}>
                        {getHeaders(h)}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tm-table-right" />
          </div>
        </td>
      )}
      <td className={`tm-quote${!isAnyOperationAllowed ? ' tm-quote-expanded' : ''}`} colSpan={!isAnyOperationAllowed ? 2 : 1}>
        <GHref
          onClick={() => selectAndShowQuickView(freight)}
          className={`tm-table-quote-btn tm-open-transaction-sidebar ${!isAnyOperationAllowed ? ' span-2' : ''}`}
        >
          <GImage
            src="icon-quote.svg"
            width="24"
            height="24"
            className="cursor-pinter"
            onClick={() => selectAndShowQuickView(freight)}
          />
        </GHref>
      </td>
      {isAnyOperationAllowed && (
        <td className="col-checkbox more pos-rel">
          <>
            <GHref
              className="tm-table-trans-more-btn"
              onClick={() => setShowRowMenu(freight?.id)}
            >
              <GImage
                src="icon-options.svg"
                className="cursor-pinter"
                onClick={() => setShowRowMenu(freight?.id)}
              />
            </GHref>
            {showRowMenu === freight.id && (
              <div className="tm-trans-more-buttons opened">
                <GHref className="tm-button-bordered color-brown" href={`/fex/freight-board/edit/${freight?.id}${!isEmpty(query) ? `?fromList=${JSON.stringify(query)}` : ''}`}>
                  <GImage
                    src="icon-edit.svg"
                    width={16}
                    height={16}
                  />
                  {t('table:edit_freight')}
                </GHref>
                <GHref
                  className="tm-button-bordered color-brown"
                  onClick={() => removeFreight(freight?.id, freight?.firstPickup?.location?.label, freight?.lastDelivery?.location?.label)}
                  submitting={loadingDeleteFreight}
                >
                  <GImage
                    src="icon-trash.svg"
                    width={16}
                    height={16}
                  />
                  {isRemoveConfirmed ? t('common:confirm') : t('table:remove_from_board')}
                </GHref>
                <GHref className="tm-trans-more-buttons-close" onClick={() => setShowRowMenu('')}>
                  <GImage
                    src="icon-close.svg"
                    width={20}
                    height={20}
                  />
                </GHref>
              </div>
            )}
          </>
        </td>
      )}
    </tr>
  )
}

export default Freight
