import { QuickFilter } from 'mp-fex/hooks/useQuickFilters'
import useTranslation from 'next-translate/useTranslation'
import SliderCarousel from 'mp-common/components/Slider'
import { TenderBoardRequestType } from 'mp-tender/types/TenderBoardTypes'
import { removeNullArrayKeys } from 'mp-structure/libs/utils'
import { forEach } from 'lodash'
import { dispatchConfirmActionModal } from 'mp-common/stores/modal/action'
import { useDispatch } from 'react-redux'

type Props = {
  defaultQuickFilters: QuickFilter[]
  queryForm: TenderBoardRequestType,
  applyFilter: (_filters?: Record<string, any>, isResetAction?: boolean) => void,
  updateQuickFilters: any,
  quickFilters:any
}

export const formToQuery = (_filters = {}) => {
  const params = new URLSearchParams()
  let _form : Record<string, any> = { ..._filters }
  _form = removeNullArrayKeys(_form)
  forEach(Object.keys(_form), k => {
    const v = _form[k]
    if (v && v !== 'false') {
      if (typeof v === 'object') {
        params.append(k, JSON.stringify(v))
      } else if (Array.isArray(v)) {
        params.append(k, encodeURIComponent(JSON.stringify(v)))
      } else {
        params.append(k, v as string)
      }
    }
  })
  return params.toString()
}

const QuickFilters = (props: Props) => {
  const { t } = useTranslation('')
  const { defaultQuickFilters, quickFilters, updateQuickFilters, applyFilter, queryForm } = props
  const dispatch = useDispatch()

  const openConfirmationModal = (item) => dispatchConfirmActionModal({
    onSubmit: () => {
      updateQuickFilters(item, true)
      dispatchConfirmActionModal(false, dispatch)
    },
    title: t('table:delete_filter_prompt', { filter_name: item.name })
  }, dispatch)

  return (
    <div className="quick-filters">
      <div className="d-flex gap-8">
        {defaultQuickFilters?.map((filter) => (
          <div
            className={formToQuery(filter?.form) === formToQuery(queryForm) ? 'swiper-slide cursor-pointer active' : 'swiper-slide cursor-pointer'}
            key={filter.name}
            onClick={() => applyFilter(filter.form)}
          >
            {filter.name}
          </div>
        ))}
      </div>
      <SliderCarousel
        items={quickFilters}
        onDelete={(item) => openConfirmationModal(item)}
        onClick={(item) => applyFilter(item)}
        queryForm={queryForm}
        formatForm={formToQuery}
      />
    </div>

  )
}

export default QuickFilters
