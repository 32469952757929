import { useCallback, useEffect, useState } from 'react'
import { freightApiPost } from 'mp-structure/libs/freightApi'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import useFreightBoardCommunication from 'mp-communication/hooks/useFreightBoardCommunication'
import { defaultFreightBoardFilters } from 'mp-freight/hooks/useFreightBoardFilters'
import useQuickView from 'mp-fex/hooks/useQuickView'
import { useDebounce } from 'use-debounce'

type Props = {
  setSummary?: (item:any)=>void,
  setPagination?: (item:any)=>void,
  fetchSummary?: () => void,
  pagination: any,
  localForm: any
}

const useFreightBoard = (props: Props) => {
  const { setSummary, fetchSummary, localForm, setPagination } = props
  const [freightList, setFreightList] = useState<any[]>([])
  const [listLoading, setListLoading] = useState(false)
  const [isRemoveConfirmed, setIsRemoveConfirmed] = useState(false)
  const [showRowMenu, setShowRowMenu] = useState<string>('')
  const {
    clearSelectedListItem,
    addSelectedListItem,
    addRemoveSelectedListItem,
    selectedListItem,
    showQuickView,
    setShowQuickView,
    selectAndShowQuickView,
    resetQuickView
  } = useQuickView()
  const [debouncedForm] = useDebounce(localForm, 500)

  const fetchFreightList = async (params = null, scrollCall = false) => {
    setListLoading(true)
    const _params = (isEmpty(params) || isNil(params)) ? { ...defaultFreightBoardFilters } : { ...params }
    if (_params?.locationsFrom) {
      _params.locationsFrom = _params?.locationsFrom?.map((location) => ({
        lat: location?.latitude,
        lng: location?.longitude,
        label: location?.label,
        ...(location?.cityName && { cityName: location?.cityName }),
        ...(location?.countryCode && { countryCode: location?.countryCode })
      }))
    }
    if (_params?.locationsTo) {
      _params.locationsTo = _params?.locationsTo?.map((location) => ({
        lat: location?.latitude,
        lng: location?.longitude,
        label: location?.label,
        ...(location?.cityName && { cityName: location?.cityName }),
        ...(location?.countryCode && { countryCode: location?.countryCode })
      }))
    }
    if (_params?.freightTargetPrice && typeof _params.freightTargetPrice === 'string') {
      _params.freightTargetPrice = parseFloat(_params.freightTargetPrice)
    }
    if (_params?.specialRequirements && Array.isArray(_params.specialRequirements)) {
      _params.specialRequirements.forEach(item => {
        _params[item] = true
      })
    }

    try {
      const res = await freightApiPost('/shipments', _params)
      if (res?.data?.data) {
        setFreightList(scrollCall ? [...freightList, ...res.data.data] : res.data.data)
      }

      const respPagination = res?.data?.pagination
      if (respPagination) {
        setPagination(respPagination)
      }

      if (JSON.stringify(params) === JSON.stringify(defaultFreightBoardFilters)) {
        await fetchSummary()
      } else {
        setSummary({
          total: respPagination.total,
          new: 0,
          ...(res?.data?.message && { message: res.data.message })
        })
      }
      setListLoading(false)
    } catch (e) {
      setFreightList([])
      setListLoading(false)
    }
  }

  const onRefresh = useCallback(() => {
    if (localForm === null || localForm.page === 1) {
      fetchFreightList(localForm)
    }
  }, [localForm])

  useFreightBoardCommunication({ onRefresh })

  useEffect(() => setIsRemoveConfirmed(false), [setShowRowMenu])

  useEffect(() => () => (setIsRemoveConfirmed(false)), [])

  useEffect(() => {
    fetchFreightList(debouncedForm)
  }, [debouncedForm])

  return {
    freightList,
    listLoading,
    setListLoading,
    fetchFreightList,
    localForm,
    showRowMenu,
    setShowRowMenu,
    isRemoveConfirmed,
    setIsRemoveConfirmed,
    clearSelectedListItem,
    addSelectedListItem,
    addRemoveSelectedListItem,
    selectedListItem,
    showQuickView,
    setShowQuickView,
    selectAndShowQuickView,
    resetQuickView
  }
}

export default useFreightBoard
